import React from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Ticket, Variant } from '@ttstr/api/products';
import {
  Container,
  ProductInfoBar,
  VariantListing,
  MerchantInfo,
  ContentrArea,
  Loading,
  TicketMarkup,
} from '@ttstr/components';
import AbsoluteRedirect from '@ttstr/components/Routing/AbsoluteRedirect';
import { isAbsoluteURL } from '@ttstr/utils';

import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import FormField from '@ttstr/components/Form/FormField';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';

const SeatingPlan = React.lazy(() => import('@ttstr/components/ProductDetail/SeatingPlan'));

interface OwnProps {
  readonly product: Ticket;
}

type Props = Readonly<OwnProps>;

const TicketDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState('best-place');

  // add_to_cart
  const addToCortTrackingHook = (variant: Variant, quantity: number) => {
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'add_to_cart',
        currency: 'EUR',
        value: String(variant.total_price),
        items: [
          {
            item_id: product.id,
            item_name: product.ptitle,
            price: String(variant.total_price),
            currency: 'EUR',
          },
        ],
        quantity: String(quantity),
      });
    }
  };

  // for gtag view_item Event
  React.useEffect(() => {
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'view_item',
        currency: 'EUR',
        value: String(product.min_price),
        items: [
          {
            item_id: product.id,
            item_name: product.ptitle,
            price: product.max_price,
            currency: 'EUR',
          },
        ],
      });
    }

    if ('fbq' in window) {
      window.fbq('track', 'ViewContent', {
        content_category: 'Detail Page',
        content_name: product.title,
      });
    }
  }, []);

  if (product.redirect) {
    return isAbsoluteURL(product.redirect) ? (
      <AbsoluteRedirect to={product.redirect} />
    ) : (
      <Redirect to={product.redirect} />
    );
  }

  return (
    <article className="ticket-detail">
      <Helmet>
        <body className="ticket-detail-page inverse-navbar" />
      </Helmet>
      <TicketMarkup ticket={product} dontChangeMetaDescription />

      <div className="product-content">
        <Card body className="article-title">
          <Container className="text-center">
            {product.supertitle && <span className="super-title">{product.supertitle}</span>}
            <h1 className="h2 my-0">
              <span className="title">{product.subtitle}</span>
              {/* <small className="sub-title">{product.subtitle}</small> */}
            </h1>
            <ContentrArea id="below_title" />
          </Container>
        </Card>
        <ProductInfoBar product={product} />

        <Container className="detail-page mt-2 mt-md-5">
          {product.has_plan && (
            <Container className="mb-3 radio-list-group">
              <Row noGutters>
                <Col className="d-flex align-items-stretch">
                  <ListGroupItem
                    className="text-center d-flex flex-column justify-content-between"
                    color="secondary"
                    tag="label"
                    active={activeTab === 'best-place'}
                    action
                  >
                    <h2 className="h5">{t('PRODUCT.BEST_PLACE_BOOKING')}</h2>
                    <p>{t('PRODUCT.BEST_PLACE_BOOKING_DESCRIPTION')}</p>
                    <FormField
                      id="activetab-best-place"
                      type="radio"
                      value="best-place"
                      checked={activeTab === 'best-place'}
                      onChange={(e) => setActiveTab(e.target.value)}
                    />
                  </ListGroupItem>
                </Col>
                <Col className="d-flex align-items-stretch">
                  <ListGroupItem
                    className="text-center d-flex flex-column justify-content-between"
                    color="secondary"
                    tag="label"
                    active={activeTab === 'seatingplan'}
                    action
                  >
                    <h2 className="h5">{t('PRODUCT.SEATINGPLAN_BOOKING')}</h2>
                    <p>{t('PRODUCT.SEATINGPLAN_BOOKING_DESCRIPTION')}</p>
                    <FormField
                      id="activetab-seatingplan"
                      type="radio"
                      value="seatingplan"
                      checked={activeTab === 'seatingplan'}
                      onChange={(e) => setActiveTab(e.target.value)}
                    />
                  </ListGroupItem>
                </Col>
              </Row>
            </Container>
          )}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="best-place" className={classNames('fade', { show: activeTab === 'best-place' })}>
              <Container className="detail-page-inner">
                <Row>
                  <Col lg={{ size: true, order: 'last' }}>
                    <div className="product-info">
                      <h2 className="product-title text-center">{t('TICKET.CHOOSE')}</h2>
                      {product.allow_bestplace !== null && product.allow_bestplace === false ? (
                        <>
                          <p className="bestplace_not_available-description">
                            {t('PRODUCT.BEST_PLACE_BOOKING_NOT_AVAILABLE')}
                          </p>
                          <Button
                            className="bestplace_not_available-button"
                            onClick={() => setActiveTab('seatingplan')}
                          >
                            {t('PRODUCT.GOTO_SEATINGPLAN_BOOKING')}
                          </Button>
                        </>
                      ) : (
                        <VariantListing
                          product={product}
                          className="mb-5 bg-white"
                          addToCartTrackingHook={addToCortTrackingHook}
                        />
                      )}
                      <ContentrArea id="after-listing" />
                    </div>
                  </Col>
                  <Col lg={{ size: true, order: 'first' }}>
                    <h2 className="product-title">{t(`PRODUCT.INFORMATION`)}</h2>
                    {product.description && <div dangerouslySetInnerHTML={{ __html: product.description }} />}
                    {product.merchant_id && <MerchantInfo merchantId={product.merchant_id} />}
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId="seatingplan" className={classNames('fade', { show: activeTab === 'seatingplan' })}>
              <Container className="container-lg">
                <Loading>
                  {activeTab === 'seatingplan' && product.has_plan && (
                    <SeatingPlan
                      product={product}
                      variants={
                        Array.isArray(product.online_variants_attributes)
                          ? product.online_variants_attributes
                          : Object.values(product.online_variants_attributes)
                      }
                    />
                  )}
                </Loading>
              </Container>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </article>
  );
};

export default React.memo(TicketDetail);
