import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product, Variant } from '@ttstr/api/products';
import { Container, NativeSlider, VariantChooser, ContentrArea, ProductMarkup } from '@ttstr/components';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  const thumbs = React.useMemo(
    () => [product.hero_image.thumb.url, ...product.gallery_image_urls.map((i) => i.thumb)],
    [product]
  );

  // add_to_cart
  const addToCartTrackingHook = (variant: Variant, quantity: number) => {
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'add_to_cart',
        currency: 'EUR',
        value: String(variant.total_price),
        items: [
          {
            item_id: product.id,
            item_name: product.ptitle,
            price: String(variant.total_price),
            currency: 'EUR',
          },
        ],
        quantity: String(quantity),
      });
    }
  };

  // for gtag view_item Event
  React.useEffect(() => {
    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'view_item',
        currency: 'EUR',
        value: String(product.min_price),
        items: [
          {
            item_id: product.id,
            item_name: product.ptitle,
            price: product.max_price,
            currency: 'EUR',
          },
        ],
      });
    }

    if ('fbq' in window) {
      window.fbq('track', 'ViewContent', {
        content_category: 'Detail Page',
        content_name: product.ptitle,
      });
    }
  }, []);

  return (
    <article className="product-detail pt-5">
      <Helmet>
        {/* <title>
          {product.supertitle && product.supertitle + ' - '}
          {product.title.replace('<wbr>', '')}
          {product.subtitle && ' - ' + product.subtitle}
        </title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} /> */}
        <body className="product-detail-page" />
      </Helmet>
      <ProductMarkup product={product} />

      <section className="product-content">
        <Container className="detail-page">
          <Row>
            <Col lg="6">
              <NativeSlider
                images={images.length ? images : [product.hero_image.url]}
                thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
              />
            </Col>
            <Col lg="6">
              <header className="text-center text-lg-left">
                {product.supertitle && <span className="super-title text-muted">{product.supertitle}</span>}
                <h1 className="product-header-title">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: product.title === 'Weihnachtssocken' ? 'Weihnachts<wbr>socken' : product.title,
                    }}
                  />
                  <small className="sub-title">{product.subtitle}</small>
                </h1>
              </header>

              <ContentrArea id="before-variants" />
              <VariantChooser className="mb-4" product={product} addToCartTrackingHook={addToCartTrackingHook} />
              <ContentrArea id="after-variants" />

              {product.description && (
                <>
                  {/* <h2 className="product-title text-left">{t(`PRODUCT.INFORMATION`)}</h2> */}
                  <div className="mt-4" dangerouslySetInnerHTML={{ __html: product.description }} />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
